import React from 'react';
import './signDocs.css';

function HIPPA() {
  return (
    <div className='hippa-root'>
      <h6>
        <b>HIPAA NOTICE OF PRIVACY PRACTICES</b>
      </h6>
      <p className='hippa-font-15'>
        <b>
          THIS NOTICE APPLIES TO CONVENIENTMD (US, WE, COMPANY) AND DESCRIBES:
          (I) YOUR RIGHTS REGARDING PROTECTED HEALTH INFORMAITON (PHI), AS
          DEFINED BY THE HEALTH INSURANCE PORTABILITY AND ACCOUNTABILITY ACT
          (HIPAA), THAT WE RECEVIE, ACQUIRE, OR PROCESS IN CONNECTION WITH YOUR
          TREATMENT.
        </b>
      </p>
      <h6>
        <b>1. Uses and Disclosures of PHI</b>
      </h6>
      <p>Your PHI may be used and disclosed for the following purposes:</p>
      <p>
        {' '}
        <b>Treatment: </b> We will use and disclose your PHI to provide,
        coordinate, or manage your health care treatment and any related
        services. This includes the coordination or management of your health
        care with a third party. For example, we may disclose your PHI to a home
        health agency that provides care for you or to a physician to whom you
        have been referred to ensure that the physician has the necessary
        information to diagnose or treat you.
      </p>
      <p>
        <b>Payment: </b> We may use and disclose your PHI to obtain payment for
        your health care services. This may include certain activities that your
        health insurance plan may undertake before it approves or pays for the
        health care services we recommend for you such as; making a
        determination of eligibility or coverage for insurance benefits,
        reviewing services provided to you for medical necessity, and
        undertaking utilization review activities. For example, obtaining
        approval for a hospital stay may require that your relevant PHI be
        disclosed to the health plan to obtain approval for the hospital
        admission.
      </p>
      <p>
        <b>Healthcare Operations: </b> We may use or disclose, as needed, your
        PHI in order to support our business activities or of our physician’s
        practice. These activities include, but are not limited to, quality
        assessment activities, employee review activities, training of medical
        students, licensing, and conducting or arranging for other business
        activities.
      </p>
      <p>
        <b>Business Associates: </b>
        We may share your PHI with third party ‘’business associates” that
        perform various activities (e.g., billing, transcription services) on
        our behalf. Whenever an arrangement between our office and a business
        associate involves the use or disclosure of your PHI, we will have a
        written contract that contains terms and procedures that will protect
        the privacy and security of your PHI, as required by HIPAA.
      </p>
      <p>
        <b>Other uses and disclosures: </b>
        In addition to the foregoing, we may use or disclose your PHI for the
        following reasons: as required by law to address public health issues,
        in conjunction with health oversight of communicable diseases, pursuant
        to an investigation of abuse or neglect, as required by the Food and
        Drug Administration, pursuant to legal proceedings, to assist coroners,
        to assist funeral directors, pursuant to organ donation & research,
        military activity and national security, and required disclosures
        related to correctional facility inmates. We are required to make
        discourses to you as required by the Secretary of the Department of
        Health and Human Services to investigate or determine our compliance
        with the requirement of Sections 164.500. We may use or disclose your
        PHI as necessary to provide you with information about treatment
        alternatives or other health-related benefits and services that may be
        of interest to you. We may also use and disclose your protected health
        information for other marketing activities. For example, your name and
        address may be used to send you information about products or services
        that we believe may be beneficial to you. You may contact our Privacy
        Officer to request that these materials not be sent to you. Other
        permitted and required uses and disclosures will be made only with your
        consent, authorization or opportunity to object unless required by law.
      </p>
      <p>
        You may revoke, this authorization, at any time, in writing, except to
        the extent that your physician or the physician’s practice has already
        taken an action in reliance on the use or disclosure indicated in the
        provided authorization.
      </p>
      <p>
        <b>
          2.
          <u> Your Rights</u>
        </b>
      </p>
      <p>
        The following is a statement of your rights with respect to your PHI.
        You may:
      </p>
      <p>
        <b>
          a.
          <u> Inspect and request an electronic copy your PHI. </u>
        </b>
        This means you may inspect and obtain an electronic copy of the PHI
        about you that is contained in a designated record set for as long as we
        maintain the PHI. A “designated record set” contains medical and billing
        records and any other records that your physician and the practice use
        for making decisions about you. Under federal law, however, you may not
        inspect or copy the following records; psychotherapy notes; information
        compiled in reasonable anticipation of, or use in, a civil, criminal, or
        administrative action or proceeding, and protected health information
        that is subject to law that prohibits access to PHI.
      </p>
      <p>
        <b>
          b. <u> Request restriction on your PHI.</u>
        </b>
        This means you may ask us not to use or disclose any part of your PHI
        for the purposes of treatment, payment, or healthcare operations. You
        may also request that any part of your PHI not be disclosed to family
        members or friends who may be involved in your care or for notification
        purposes as described in this Notice. Your request must state the
        specific restriction requested and to whom you want the restriction to
        apply. We are not required to agree to a restriction that you may
        request. If we believe it is in your best interest to permit use and
        disclosure of your PHI, your PHI will not be restricted. You then have
        the right to use another healthcare professional.
      </p>
      <p>
        <b>
          c. <u> Request correction.</u>
        </b>
        You may ask us to correct or amend your PHI that we maintain about you
        that you think is incorrect or inaccurate.
      </p>
      <p>
        <b>
          d.{' '}
          <u>
            Request to receive confidential communications from us by
            alternative means or at an alternative location.
          </u>{' '}
        </b>
        We will accommodate reasonable requests. We may also condition this
        accommodation by asking you for information as to how payment will be
        handled or specification of an alternative address or other method of
        contact. We will not request an explanation for you as to the basis for
        the request.
      </p>
      <p>
        <b>
          e. <u> Request accounting of disclosures.</u>
        </b>
        You may ask for a list (accounting) of the times we have shared your PHI
        for six years prior to the date you ask, who we shared it with, and why
        (which we will provide without charge once each year, and subject to a
        reasonable, cost-based fee thereafter).
      </p>
      <p>
        <b>
          f.{' '}
          <u>
            Receive an accounting of certain disclosures we have made, if any of
            your PHI.
          </u>
        </b>
        This right applies to disclosures for purposes other than treatment,
        payment or healthcare operations as described in this Notice.
      </p>
      <p>
        <b>
          g.
          <u> Choose someone to act on your behalf.</u>
        </b>
        You can choose someone to act for you, provided we can confirm that
        person has the necessary authority and documentation (such as a medical
        power of attorney) to act for you before we take any action.
      </p>
      <p>
        <b>
          h.
          <u> Get a copy of this Notice.</u>
        </b>
      </p>
      <p>
        <b>
          3. <u> Our Responsibilities</u>{' '}
        </b>{' '}
      </p>
      <p>
        We are required to abide by the terms of this Notice. We will let you
        know promptly if a breach occurs that may have compromised the privacy
        or security of your PHI no later than 60 days after we confirm such a
        breach. We must follow the duties and privacy practices described in
        this notice and give you a copy of it. We will not use or share your PHI
        other than as described here unless you tell us we can in writing. If
        you tell us we can use or share your PHI in a particular way, you may
        change your mind at any time and can send us different written
        instructions at that time.
      </p>

      <p>
        {' '}
        We may change the terms of our Notice at any time. The new Notice will
        be effective for all PHI that we maintain at this time. Upon your
        request, we will provide you with any revised Notice by calling the
        office and requesting that a revised copy be sent to you in the mail or
        asking for one at the time of your next appointment.
      </p>
      <p>
        <b>
          4. <u> Your Choices</u>{' '}
        </b>
      </p>
      <p>
        {' '}
        You may instruct us about who we share your PHI with and how much PHI we
        share. For example, you may tell us to share information with your
        family, close friends, or others in a disaster relief situation. Except
        as stated in this Notice, we do not share your PHI unless you instruct
        us to do so.
      </p>
      <p>
        <b>
          5. <u> Complaints</u>
        </b>
        <p>
          {' '}
          You have the right to complain if you feel we have violated your
          rights. We will not retaliate against you for filing a complaint. If
          you believe your rights have been violated, you can file a complaint:
        </p>

        <ul>
          <li>
            Directly with us by contacting our Privacy Officer as follows:
          </li>
          <div>Phone: (603) 319-4529</div>

          <div>
            Email:{' '}
            <a
              href='mailto:DataPrivacy@convenientmd.com'
              style={{ textDecoration: 'none', color: 'inherit' }}
            >
              DataPrivacy@convenientmd.com
            </a>
          </div>
          <div>Address: 360 US-1 Bypass, #102, Portsmouth, NH 03801 </div>
        </ul>
        <ul>
          <li>
            With the U.S. Department of Health and Human Services Office for
            Civil Rights by sending a letter to 200 Independence Avenue, S.W.,
            Washington, D.C. 20201, calling 1-877-696 6775, or visiting
            www.hhs.gov/ocr/privacy/hipaa/complaints/ (for which we will not
            retaliate against you).{' '}
          </li>
        </ul>
      </p>
    </div>
  );
}

export default HIPPA;
