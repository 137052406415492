import React from 'react';
import './signDocs.css';

function PatientBillOfRights() {
  return (
    <div className='patient-rights-root'>
      <h6>
        <b>PATIENT’S BILL OF RIGHTS</b>
      </h6>
      <ol type='I'>
        <li>
          The patient shall be treated with consideration, respect, and full
          recognition of the patient’s dignity and individuality, including
          privacy in treatment and personal care and including being informed of
          the name, licensure status, and staff position of all those with whom
          the patient has contact, pursuant to RSA 151:3-b.
        </li>
        <li>
          The patient shall be fully informed of a patient’s rights and
          responsibilities and of all procedures governing patient conduct and
          responsibilities. This information must be provided orally and in
          writing before or at admission, except for emergency admissions.
          Receipt of the information must be acknowledged by the patient in
          writing. When a patient lacks the capacity to make informed judgments
          the signing must be by the person legally responsible for the patient.
        </li>
        <li>
          The patient shall be fully informed in writing, in language that the
          patient can understand, before or at the time of admission and as
          necessary during the patient’s stay, of the facility’s basic per diem
          rate and of those services included and not included in the basic per
          diem rate. A statement of services that are not normally covered by
          Medicare or Medicaid shall also be included in this disclosure.
        </li>
        <li>
          The patient shall be fully informed by a health care provider of his
          or her medical condition, health care needs, and diagnostic test
          results, including the manner by which such results will be provided
          and the expected time interval between testing and receiving results,
          unless medically inadvisable and so documented in the medical record,
          and shall be given the opportunity to participate in the planning of
          his or her total care and medical treatment, to refuse treatment, and
          to be involved in experimental research upon the patient’s written
          consent only. For the purposes of this paragraph “health care
          provider” means any person, corporation, facility, or institution
          either licensed by this state or otherwise lawfully providing health
          care services, including, but not limited to, a physician, hospital or
          other health care facility, dentist, nurse, optometrist, podiatrist,
          physical therapist, or psychologist, and any officer, employee, or
          agent of such provider acting in the course and scope of employment or
          agency related to or supportive of health care services.
        </li>
        <li>
          The patient shall be transferred or discharged after appropriate
          discharge planning only for medical reasons, for the patient’s welfare
          or that of other patients, if the facility ceases to operate, or for
          nonpayment for the patient’s stay, except as prohibited by Title XVIII
          or XIX of the Social Security Act. No patient shall be involuntarily
          discharged from a facility because the patient becomes eligible for
          Medicaid as a source of payment.
        </li>
        <li>
          The patient shall be encouraged and assisted throughout the patient’s
          stay to exercise the patient’s rights as a patient and citizen. The
          patient may voice grievances and recommend changes in policies and
          services to facility staff or outside representatives free from
          restraint, interference, coercion, discrimination, or reprisal.
        </li>
        <li>
          The patient shall be permitted to manage the patient’s personal
          financial affairs. If the patient authorizes the facility in writing
          to assist in this management and the facility so consents, the
          assistance shall be carried out in accordance with the patient’s
          rights under this subdivision and in conformance with state law and
          rules.
        </li>
        <li>
          The patient shall be free from emotional, psychological, sexual and
          physical abuse and from exploitation, neglect, corporal punishment and
          involuntary seclusion.
        </li>
        <li>
          The patient shall be free from chemical and physical restraints except
          when they are authorized in writing by a physician for a specific and
          limited time necessary to protect the patient or others from injury.
          In an emergency, restraints may be authorized by the designated
          professional staff member in order to protect the patient or others
          from injury. The staff member must promptly report such action to the
          physician.
        </li>
        <li>
          The patient shall be ensured confidential treatment of all information
          contained in the patient’s personal and clinical record, including
          that stored in an automatic data bank, and the patient’s written
          consent shall be required for the release of information to anyone not
          otherwise authorized by law to receive it. Medical information
          contained in the medical records at any facility licensed under this
          chapter shall be deemed to be the property of the patient. The patient
          shall be entitled to a copy of such records upon request. The charge
          for the copying of a patient’s medical records shall not exceed $15
          for the first 30 pages or $.50 per page, whichever is greater;
          provided, that copies of filmed records such as radiograms, x-rays,
          and sonograms shall be copied at a reasonable cost.
        </li>
        <li>
          The patient shall not be required to perform services for the
          facility. Where appropriate for therapeutic or diversional purposes
          and agreed to by the patient, such services may be included in a plan
          of care and treatment.
        </li>
        <li>
          The patient shall be free to communicate with, associate with, and
          meet privately with anyone, including family and resident groups,
          unless to do so would infringe upon the rights of other patients. The
          patient may send and receive unopened personal mail. The patient has
          the right to have regular access to the unmonitored use of a
          telephone.
        </li>
        <li>
          The patient shall be free to participate in activities of any social,
          religious, and community groups, unless to do so would infringe upon
          the rights of other patients.
        </li>
        <li>
          The patient shall be free to retain and use personal clothing and
          possessions as space permits, provided it does not infringe on the
          rights of other patients.
        </li>
        <li>
          The patient shall be entitled to privacy for visits and, if married,
          to share a room with his or her spouse if both are patients in the
          same facility and where both patients consent, unless it is medically
          contraindicated and so documented by a physician. The patient has the
          right to reside and receive services in the facility with reasonable
          accommodation of individual needs and preferences, including choice of
          room and roommate, except when the health and safety of the individual
          or other patients would be endangered.
        </li>
        <li>
          The patient shall not be denied appropriate care on the basis of age,
          sex, gender identity, sexual orientation, race, color, marital status,
          familial status, disability, religion, national origin, source of
          income, source of payment, or profession.
        </li>
        <li>
          The patient shall be entitled to be treated by the patient’s physician
          of choice, subject to reasonable rules and regulations of the facility
          regarding the facility’s credentialing process.
        </li>
        <li>
          The patient shall be entitled to have the patient’s parents, if a
          minor, or spouse, or next of kin, unmarried partner, or a personal
          representative chosen by the patient, if an adult, visit the facility,
          without restriction, if the patient is considered terminally ill by
          the physician responsible for the patient’s care.
        </li>
        <li>
          The patient shall be entitled to receive representatives of approved
          organizations as provided in RSA 151:28.
        </li>
        <li>
          The patient shall not be denied admission to the facility based on
          Medicaid as a source of payment when there is an available space in
          the facility.
        </li>
        <li>
          Subject to the terms and conditions of the patient’s insurance plan,
          the patient shall have access to any provider in his or her insurance
          plan network and referral to a provider or facility within such
          network shall not be unreasonably withheld pursuant to RSA 420-J:8,
          XIV.
        </li>
        <li>
          This consent and release does not apply to any DOT drug and alcohol
          testing.
        </li>
      </ol>
      <p>
        If you wish to express a compliment, concern or complaint regarding
        ConvenientMD, please contact the Practice Manager of the location in
        which the concern originated. While we prefer to speak with you
        personally about your concern, you may also send us a letter through
        mail:
      </p>
    </div>
  );
}

export default PatientBillOfRights;
