import React from 'react';
import './signDocs.css';

function PrivacyPolicy() {
  return (
    <>
      <div className='privacy-root'>
        <h6>Privacy Policy</h6>
        <p className='text-indent-30'>
          ConvenientMD, LLC (CMD) respects the privacy of information that
          identifies you or is identifiable to you (Personal Information). We
          want you to understand how CMD processes your Personal Information,
          and how you can submit privacy rights requests to us concerning your
          Personal Information, in compliance with the various laws in the
          United States that govern CMD’s processing of Personal Information
          (Data Privacy Laws). Our processing of Personal Information is
          governed by those Data Privacy Laws as well as this Privacy Policy
          (Policy).
        </p>
        <p className='text-indent-30'>
          Protected health information (PHI) as defined under the Health
          Insurance Portability & Accountability Act and related regulations
          (HIPAA) is covered separately in our HIPAA Notice of Privacy
          Practices. For purposes of this Policy, PHI is not a category of
          Personal Information or Sensitive Personal Information, and is not
          covered by this Policy.
        </p>
        <p className='text-indent-30'>
          <b>1. Data Privacy Officer and Security Policy </b>
        </p>
        <p>
          <p className='text-indent-30'>
            For questions about CMD’s processing of your Personal Information or
            this Policy, please contact our Data Privacy Officer as follows:{' '}
          </p>
          <div>Phone: (603) 319-4529</div>
          <div>
            Email:{' '}
            <a
              href='mailto:DataPrivacy@convenientmd.com'
              style={{ textDecoration: 'none', color: 'inherit' }}
            >
              DataPrivacy@convenientmd.com
            </a>
          </div>
          <div>Address: 360 US-1 Bypass, #102, Portsmouth, NH 03801 </div>
        </p>
        <p className='text-indent-30'>
          <b>2. Personal Information </b>
        </p>
        <p className='text-indent-30'>
          CMD provides healthcare services, including urgent care, primary care,
          and virtual care (Services) to individuals and their families. In the
          course of providing the Services, CMD receives from individuals and
          their families, itself collects, and processes Personal Information,
          which may include the following: (1) identifiers, such as first and
          last name; email address; billing address; and phone number; (2)
          demographic information, such as your age, date of birth, sex and/or
          gender, race and/or ethnicity, and language preferences; (3) account
          information, such as your username and password, if you create an
          account with us (4) financial information, such as your credit or
          debit card information; provided however that CMD does not store or
          has access to any such payment and credit information and uses a
          third-party payment processor to process all such payments.
        </p>
        <p className='text-indent-30'>
          In certain situations, CMD may partner with certain third parties and
          use third party tools in the course of providing Services, and our
          website may contain links to sites provided by third parties. CMD has
          no control over the policies and practices of third-party websites as
          to privacy or anything else. Please review the applicable third-party
          website’s policies for further information regarding their collection
          and processing of Personal Information. The manner in which these
          third parties collect, and process Personal Information is governed by
          the policies that apply to these third parties.
        </p>
        <p className='text-indent-30'>
          Depending on how you interact with us, we may collect Personal
          Information when you: (1) visit our website; (2) register for, inquire
          about, or use the Services; (3) create an account with us on our
          patient portal, or otherwise use or access the Services; (3) fill out
          or complete any form available on our website; (4) communicate with us
          directly or indirectly via email or other methods; (5) subscribe to
          our mailing lists, newsletter, or other forms of communications; (6)
          respond to a survey or enter a promotion; and (5) otherwise interact
          with us.{' '}
        </p>
        <p className='text-indent-30'>
          CMD has collected Personal Information in the last twelve months and
          processed it for the purposes described in this Policy.{' '}
        </p>
        <p className='text-indent-30'>
          <b>3. Sensitive Information Processed </b>
        </p>
        <p className='text-indent-30'>
          In addition, in the course of providing Services, CMD may receive,
          itself collect, and process certain types of Personal Information that
          is of a sensitive nature (Sensitive Personal Information), including
          information about the following: (1) information about individuals who
          are minors in the jurisdiction where they reside; (2) Social Security
          number; (3) governmental identification number, including, but not
          limited to, driver’s license, non-driver identification, passport, and
          military identification number; (4) credit card, debit card, and bank
          and other financial account number, and other card holder information
          (except to the extent we require you to do so); provided however that
          CMD does not store or has access to any such payment and credit
          information and uses a third-party payment processor to process all
          such payments; (5) race, ethnic origin, national origin, citizenship,
          immigration status, religious and political belief and affiliation,
          and sexual orientation and identity; (6) biometric information; and
          (7) precise geolocation information.
        </p>
        <p className='text-indent-30'>
          <b>4. Legal Bases and Purpose for Processing Personal Information </b>
        </p>
        <p className='text-indent-30'>
          CMD has contractual and other commercial, business, and legal
          relationships to provide Services to individuals and their families.
          As a result, individuals and their families provide Personal
          Information to CMD and CMD collects and processes Personal Information
          to provide Services to them. In addition, CMD seeks and obtains
          consent for the use of Personal Information if and to the extent
          consent is required for processing of Personal Information under Data
          Privacy Laws. That is the legal basis for CMD to process Personal
          Information.
        </p>
        <p className='text-indent-30'>
          CMD may process Personal Information to: (1) provide, develop,
          maintain, and enhance the Services, and our website; (2) communicate
          with you about the Services; (3) provide support for the Services
          including responding to requests, questions, feedback and technical
          support; (4) process and complete transactions, and send related
          information, including purchase confirmations and invoices; (5)
          personalize your experience with the website; (6) send you
          communications about our Services that we believe may be of interest,
          such as information about features, offers, catalogues, promotions,
          and events; (7) maintain the safety, security, and integrity of our
          Services, and our website; (8) comply with legal and regulatory
          obligations, and (9) establish, enforce, and otherwise exercise our
          rights and defend against claims.{' '}
        </p>
        <p className='text-indent-30'>
          <b>5. Disclosure of Personal Information</b>{' '}
        </p>
        <p className='text-indent-30'>
          Except as necessary or appropriate to provide Services, CMD does not
          disclose Personal Information about you to other unaffiliated third
          parties, provided that we may disclose Personal Information for the
          following purposes and in the following circumstances.
        </p>
        <ul>
          <li>
            If directed to do so by the individual about whom such Personal
            Information pertains or that individual’s legal representative{' '}
          </li>
          <li>
            If required to do so by Data Privacy Law, any other law, rule, or
            regulation, legal process or warrant, or order of any court or
            governmental agency or authority
          </li>
          <li>
            To our agents and service providers necessary or appropriate to
            provide the Services, such as information technology and security
            providers, data hosting services, providers that process payments,
            attorneys, accountants, tax professionals, financial advisers, or
            any other third party that is necessary or appropriate for CMD to
            disclose Personal Information to in order to provide the Services.
            Unless we tell you differently, our agents and service providers do
            not have any right to use the Personal Information we share with
            them beyond what is necessary to assist us in providing Services to
            you.{' '}
          </li>
          <li>
            In connection with a sale, merger, consolidation, change in control,
            transfer of substantial assets, reorganization, or liquidation of
            CMD{' '}
          </li>
          <li>
            If we determine that doing so would be in the public’s interest{' '}
          </li>
          <li>For protection of CMD and others.</li>
        </ul>
        <p className='text-indent-30'>
          <b>6. Disclosure for Marketing Purposes and TCPA Compliance</b>
        </p>
        <p className='text-indent-30'>
          From time to time, CMD may disclose some of your Personal Information
          to our service providers and/or subcontractors to assist us in our
          marketing activities. Our service providers and/or subcontractors do
          not have any right to use the Personal Information we share with them
          beyond what is necessary to assist us in providing Services to you. If
          and to the extent we send you any marketing communications via text
          messages, we do so in compliance with the Telephone Consumer
          Protection Act (TCPA) and based on your prior express consent (if and
          to the extent required by TCPA). You may revoke this consent at any
          time by opting-out of receiving any marketing-related communication
          via text messages by completing and submitting the Privacy Rights
          Request Form on our website. CMD does not send marketing related
          text-messages to any telephone number registered with the National Do
          Not Call Registry (Registry). For additional information on the
          Registry, or to register your telephone number(s) with the Registry,
          please see Federal Trade Commission’s National Do Not Call Registry
          webpage. Please note that not all of our communications sent via text
          messages or email are for marketing purposes, and you may continue to
          receive service messages for our Services (like bills, transactional
          notices, customer service, etc.) despite you opting-out of receiving
          marketing-relating communications from CMD.
        </p>
        <p className='text-indent-30'>
          CMD does not sell or share any of your Personal Information, including
          your mobile phone number, to any third party for marketing or
          promotional purposes without your express consent, except when legally
          required to do so. In addition, CMD does not share your consent with
          third parties, if and to the extent you provided consent to CMD for
          receiving communications via text messages.
        </p>
        <p className='text-indent-30'>
          <b>7. Information Collected Automatically</b>
        </p>
        <p className='text-indent-30'>
          We collect certain information about you automatically as you navigate
          through or use our website or Services. This information may include:
          (1) usage details, such as details of your visits to our website,
          including traffic data, location, logs, referring/exit pages, date and
          time of your visit to or use of our website, error information, and
          other communication data and the resources that you access and use on
          or in the website; (2) device information, such as information about
          your computer, mobile device, and Internet connection, specifically
          your IP address, operating system, browser type, and App version
          information; and (3) location data, if and to the extent you use a
          mobile device to access our website or download any of our
          application(s); provided however that you may disable this
          functionality at any time on your mobile device by removing permission
          for CMD to use location services.
        </p>
        <p className='text-indent-30'>
          We may automatically collect the foregoing categories of information
          using the following technologies:{' '}
        </p>
        <ul>
          <p className='text-indent-30'>
            <b>
              1.
              <u> Cookies. </u>
            </b>
            When you visit our website, CMD may place a cookie (a text file
            containing a randomly assigned number) on your device so that we
            have the ability to customize the look and feel of the website for
            you. Cookies remember information about you, your preferences, and
            your device for a better user experience when visiting the website.
            Using cookies, we may also collect a variety of other information
            regarding your usage of the website, including your Internet
            protocol address, username, session identification, browser you are
            using, web pages you view and referring web pages, when you access
            the website, and general online behavior for performance and
            marketing purposes. You may decline to accept cookies by modifying
            your web browser settings. If you choose so, however, you may be
            unable to use some or all functions of the website, or the website
            may operate less efficiently.{' '}
          </p>
          <p className='text-indent-30'>
            <b>
              2.
              <u> Web Beacons. </u>
            </b>
            Some pages on our website, or our e-mails, may contain small
            electronic files known as web beacons (also referred to as clear
            gifs, pixel tags, and single-pixel gifs) that permit us, for
            example, to count users who have visited those pages or opened an
            e-mail and for other related website statistics (for example,
            recording the popularity of certain website content and verifying
            system and server integrity).
          </p>
          <p className='text-indent-30'>
            <b>
              3.
              <u> Google Analytics. </u>
            </b>
            We may use Google Analytics and similar technologies to analyze how
            our website is used to assist us in the improvement and optimization
            of our website. These services track and examine website activities
            to prepare usage reports. To prevent Google Analytics from using
            your information for analytics, you may opt out of the Google
            Analytics service with the Google’s Browser Add-on that’s available
            at https://tools.google.com/dlpage/gaoptout/.
          </p>
        </ul>
        <p className='text-indent-30'>
          <b>8. Privacy Rights </b>
        </p>
        <p className='text-indent-30'>
          Depending on the Personal Information that CMD has about you, and
          depending on the manner in which we process your Personal Information,
          you may have the following privacy rights related to our processing of
          your Personal Information. Please note that these rights do not apply
          with respect to your PHI that we process. The rights that you have
          with respect to your PHI are more fully described in our HIPAA Notice
          of Privacy Practices.
        </p>
        <ul>
          <li>
            Be informed about how your Personal Information is being processed,
            the categories of Personal Information being processed, and the
            categories of sources from which your Personal Information has been
            collected
          </li>
          <li>
            Be informed about whether and how your Personal Information being
            processed is being disclosed to unaffiliated third parties, and
            which categories of your Personal Information are being disclosed to
            unaffiliated third parties{' '}
          </li>
          <li>Access or receive a copy of your Personal Information </li>
          <li>
            Rectify, update or correct inaccurate or incomplete Personal
            Information about you{' '}
          </li>
          <li>
            Erasure or deletion of certain categories of your Personal
            Information
          </li>
          <li>
            Object and opt-out of processing of your Personal Information{' '}
          </li>
          <li>Limit processing of your Personal Information </li>
          <li>
            Portability of your Personal Information in a commonly used format{' '}
          </li>
          <li>
            Withdrawal of your consent to processing of your Personal
            Information to the extent such processing is based on your consent,
            provided that such withdrawal will not affect the lawfulness of
            CMD’s use of your Personal Information before such withdrawal{' '}
          </li>
          <li>Non-discrimination for exercising your privacy rights </li>
        </ul>
        <p className='text-indent-30'>
          To make a request concerning these rights, please complete and submit
          the <u>Privacy Rights Request Form</u> on our website. We will confirm
          receipt of all such complete Privacy Rights Request Forms within 10
          days of receipt. We will substantively respond to all such requests
          within 30 days, subject to any lawful extension of that period.
          Additionally, there may be a delay in processing a request while we
          verify that the request is valid and originates from you as opposed to
          an unauthorized person.
        </p>
        <p className='text-indent-30'>
          Please be aware there may be valid reasons to refuse a request,
          including legal and regulatory requirements that we retain and process
          certain information. Also, please be aware that certain requests may
          limit or prevent us from providing Services to you. In those
          situations, we will notify you in writing within 30 days. You may also
          contact our Data Privacy Officer for help with those situations.
        </p>
        <p className='text-indent-30'>
          CMD will not discriminate against you if you exercise any of the
          foregoing rights or any other rights under Data Privacy Laws,
          including, by not denying Services to you, not providing a different
          level or quality of Services to you, and not suggesting that you will
          receive a different level or quality of Services.
        </p>
        <p className='text-indent-30'>
          <b>
            9. Selling, Share, Trading, Exchanging and Brokering Personal
            Information
          </b>
        </p>
        <p className='text-indent-30'>
          Except as otherwise disclosed in this Policy, CMD does not sell,
          share, trade, or exchange Personal Information (including as those
          terms are defined and used in Data Privacy Laws) in any way, including
          for itself, or any unaffiliated third party. If and to the extent you
          want to opt-out of the sale, sharing, trading, or exchanging of your
          Personal Information, please complete and submit the{' '}
          <u> Privacy Rights Request Form</u> on our website, and we will
          address such requests as described in the foregoing section of this
          Policy.{' '}
        </p>
        <p className='text-indent-30'>
          <b>10. Automated Decision Making </b>
        </p>
        <p className='text-indent-30'>
          CMD does not use automated decision making or profiling in the
          processing of your Personal Information, and does not make decisions
          that affect you based on automated decision making or profiling using
          your Personal Information.
        </p>
        <p className='text-indent-30'>
          <b>11. Duration of Retention</b>
        </p>
        <p className='text-indent-30'>
          Because the Services that CMD provides to you vary significantly, the
          duration we retain Personal Information varies significantly,
          depending on the types of Services that CMD provides to you, the
          duration and depth of CMD’s relationship with you, and the nature of
          Personal Information in question. CMD endeavors to retain Personal
          Information that it receives from or collects for only as long as is
          necessary or appropriate.
        </p>
        <p className='text-indent-30'>
          <b>12. Changes to Policy</b>
        </p>
        <p className='text-indent-30'>
          CMD reserves the right to change or amend this Policy at any time and
          for any reasons, including to remain in compliance with Data Privacy
          Laws.
        </p>
        <p>
          <b>13. Questions or Concerns </b>
        </p>
        <p>
          For questions or concerns, please contact our Data Privacy Officer.
        </p>
      </div>
    </>
  );
}

export default PrivacyPolicy;
