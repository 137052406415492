import React from 'react';
import './signDocs.css';

function ConsentToTreat() {
  return (
    <div className='consent-root'>
      <h6>
        <b>PATIENT’S CONSENT FOR TREATMENT & RELEASE OF INFORMATION</b>
      </h6>
      <p>I, the undersigned, hereby consent to the following treatment:</p>

      <ul>
        <li>
          <b>Administration and performance of all treatments.</b>
        </li>
        <li>
          <b>Administration of any needed anesthetics.</b>
        </li>
        <li>
          <b>
            Performance of such procedures as may be deemed necessary or
            advisable in the treatment of this patient.
          </b>
        </li>
        <li>
          <b>Use of prescribed medication.</b>
        </li>
        <li>
          <b>Performance of diagnostic procedures, tests, and/or cultures.</b>
        </li>
        <li>
          <b>
            Performance of other medically accepted laboratory tests that may be
            considered medically necessary or advisable based on the judgement
            of the attending physician or their assigned designee.{' '}
          </b>
        </li>
      </ul>
      <p>
        I fully understand that this is given in advance of any specific
        diagnosis or treatment.
      </p>
      <p>
        I intend this consent to be continuing in nature even after a specific
        diagnosis has been made and treatment recommended. The consent will
        remain in full force until revoked in writing.
      </p>
      <p>
        I understand that ConvenientMD may include the consent at the Satellite
        offices under common ownership.
      </p>
      <p>
        I, the undersigned, acknowledge and understand that in the course of
        providing the foregoing treatment, ConvenientMD may collect, receive,
        have access to, or create protected health information (PHI) as defined
        by HIPAA. I hereby authorize ConvenientMD to use and disclose my PHI for
        purposes of treatment, payment, healthcare operations, and as otherwise
        described in the HIPAA Notice of Privacy Practices.
      </p>
      <p>
        I understand that I have the right to revoke this authorization, in
        writing, at any time, except to the extent ConvenientMD has acted in
        reliance upon it, by contacting ConvenientMD’s Data Privacy Officer.{' '}
      </p>
      <p>
        A photocopy of this consent shall be considered as valid as the
        original.
      </p>
      <p>
        Medicare Patients: I authorize the release of PHI about me to the Social
        Security Administration or its intermediaries for my Medicare claims. I
        assign the benefits payable for services to ConvenientMD.
      </p>
      <p>
        This consent and release does not apply to any DOT drug and alcohol
        testing.{' '}
      </p>
      <p>
        I certify that I have read and fully understand the above statements and
        consent fully and voluntarily to its contents.{' '}
      </p>
    </div>
  );
}

export default ConsentToTreat;
